import React from "react"
import Prijs from '../prijs'
import Oppervlakte from "../oppervlakte"
import { statusMap } from "../../utils/utils"
import {Bouwnummer} from "../../graphqlTypes";
import {graphql} from "gatsby";

export const columns = [
    {
        name: 'Nr.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.diversen.diversen.bouwnummer,
    },
    {
        name: 'Type',
        format: (bouwnummer: Bouwnummer) => bouwnummer.bouwtype.algemeen.omschrijving,
    },
    {
        name: 'Gebruiksopp.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.algemeen?.woonoppervlakte && <Oppervlakte oppervlakte={bouwnummer.algemeen.woonoppervlakte} />,
    },
    {
        name: 'Prijs V.O.N.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.financieel?.overdracht?.koopprijs && <Prijs prijs={bouwnummer.financieel.overdracht.koopprijs} />,
    },
    {
        name: 'Status',
        format: (bouwnummer: Bouwnummer) => bouwnummer.financieel?.overdracht?.status && statusMap[bouwnummer.financieel.overdracht.status]['naam'],
    },
];

export const query = graphql`
    fragment BouwnummerPrijslijst on Bouwnummer {
        ...Bouwnummer
        bouwtype {
            algemeen {
                omschrijving
            }
        }
    }
`

import React from 'react'
import TableContainer from '@mui/material/TableContainer';
import {Bouwnummer} from "../../graphqlTypes";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import { columns } from './columns';

interface Props {
    bouwnummers: Bouwnummer[]
}
const Prijslijst = ({bouwnummers}: Props) => {

    return (
        <>
            <TableContainer
                component={Paper}
                elevation={0}
            >
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label="prijslijst"
                    size={'small'}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map(column =>
                                <TableCell
                                    key={column.name}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'primary.main'
                                    }}
                                >
                                    {column.name}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Prijslijst
import * as React from "react"
import {graphql} from "gatsby"
import Container from "@mui/material/Container";
import {Box, Grid} from "@mui/material";
import {Bouwnummer, Bouwtype} from "../graphqlTypes";
import Prijslijst from "../components/prijslijst";
import {sectionPadding, sectionSpacing} from "../theme";
import Prijzen from "../components/prijzen";
import Typography from "@mui/material/Typography";
import {bouwnummerSort, bouwtypeSort} from "../utils/utils";
import {GatsbyImage, GatsbyImageProps, getImage, StaticImage} from "gatsby-plugin-image";
import Situatie from "../components/situatie";
import {ReactNode} from "react";

interface WoningGroepProps {
  title: string
  subtitle: string
  bouwnummers: Bouwnummer[]
  image: ReactNode
}
const WoningGroep = ({title, subtitle, bouwnummers, image}: WoningGroepProps) => {
  return (
    <>
      <Typography
        variant={'h5'}
        textTransform={'uppercase'}
        marginBottom={3}
        fontWeight={'Finlandica'}
      >
        <Box component={'span'} fontWeight={'bold'}>{title}</Box> {subtitle}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {image}
        </Grid>
        <Grid item xs={12} md={8}>
          <Prijslijst bouwnummers={bouwnummers.sort(bouwnummerSort) || []} />
        </Grid>
      </Grid>
    </>
  )
}

interface Props {
  data: PageQueryData
}
export default ({data}: Props) => {
  return (
    <Box marginTop={'122px'}>
      <Container
      >
        <Situatie />
        <Typography variant={'h3'} color={'primary'} marginY={sectionSpacing}>
          Beschikbaarheid
        </Typography>
        <Box
          marginY={sectionSpacing}
        >
          <WoningGroep
            title={'De Brugwachter'}
            subtitle={'Vrijstaande woningen'}
            bouwnummers={data.allBouwnummer.nodes.filter(bouwnummer => bouwnummer.bouwtype.algemeen.omschrijving === 'Vrijstaande woning')}
            image={
              <StaticImage
                src="../images/impressies/2112_CAM3_Ontwikkelveld_zeewolde.jpg"
                alt={''}
              />
            }
          />
        </Box>
        <Box
          marginY={sectionSpacing}
        >
          <WoningGroep
            title={'De Sluiswachter'}
            subtitle={'Appartementen'}
            bouwnummers={data.allBouwnummer.nodes.filter(bouwnummer => bouwnummer.bouwtype.algemeen.omschrijving !== 'Vrijstaande woning')}
            image={
              <StaticImage
                src="../images/impressies/2112_CAM8_Ontwikkelveld_zeewolde.jpg"
                alt={''}
              />
            }
          />
        </Box>
        <Box
          marginY={sectionPadding}
        >
          <Prijzen />
        </Box>
      </Container>
    </Box>
  );
}

interface PageQueryData {
  allBouwnummer: {
    nodes: Bouwnummer[]
  }
}

export const query = graphql`
    query {
        allBouwnummer {
            nodes {
                ...BouwnummerPrijslijst
            }
        }
    } 
`